import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

// Import components
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBanner from "../components/title-banner"
import DateSelectorDesktop from "../components/date-selector-desktop"

const IntegratedReports = () => {
  const data = useStaticQuery(INTEGRATED_REPORTS)
  const imageData = data?.allImageSharp?.nodes || []
  return (
    <Layout>
      <Seo title="Home" />
      <TitleBanner imageData={imageData}>Integrated Reports</TitleBanner>
      <DateSelectorDesktop
        tabs={[[2021, 2020, 2019, 2018, 2017, 2016]]}
        types={[
          "Proxy for Annual Vote",
          "Annual Integration Report",
          "Minutes",
        ]}
      ></DateSelectorDesktop>
    </Layout>
  )
}

export default IntegratedReports

const INTEGRATED_REPORTS = graphql`
  {
    allImageSharp(
      filter: {
        fixed: {
          originalName: {
            in: [
              "banner-image-integrated-reports.png"
              "banner-image-integrated-reports-mobile.png"
            ]
          }
        }
      }
      sort: { fields: fixed___originalName, order: DESC }
    ) {
      nodes {
        gatsbyImageData
      }
    }
  }
`
